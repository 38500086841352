<template>
  <div>
    <div class="patientRoom mt10">
      <div class="mt10 mb-3">
        <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{$t('message.hospital')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('message.bunk')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="patientRoom_tabs">
        <div class="link_blocks">
          <router-link :to="{name: 'housing'}">{{$t('message.corps')}}</router-link>
          <router-link :to="{name: 'floor'}">{{$t('message.floor')}}</router-link>
          <router-link :to="{name: 'class'}">{{$t('message.class')}}</router-link>
          <router-link :to="{name: 'hospitalRooms'}">{{$t('message.room')}}</router-link>
          <router-link :to="{name: 'bunk'}">{{$t('message.bunk')}}</router-link>
          <router-link :to="{name: 'patientHistoryType'}">{{$t('message.patient_history_type')}}</router-link>
          <router-link :to="{name: 'departments'}">{{$t('message.branch')}}</router-link>
          <router-link :to="{name: 'departmentUser'}">{{$t('message.dapartment_user')}}</router-link>
          <router-link :to="{name: 'hospitalizationType'}">{{$t('message.hospitalization_type')}}</router-link>
          <router-link :to="{name: 'purposHospitalization'}">{{$t('message.purpose_of_hospitalization')}}</router-link>
          <router-link :to="{name: 'typeInjury'}">{{$t('message.type_of_injury')}}</router-link>
          <router-link :to="{name: 'injuryStatus'}">{{$t('message.conditions')}}</router-link>
          <router-link :to="{name: 'patentIndicator'}">{{$t('message.patient_indicator')}}</router-link>
          <router-link :to="{name: 'pockets'}">{{$t('message.pockets')}}</router-link>
        </div>
        <div class="blocks minH m-3">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "bunk",
  data() {
    return {};
  }
};
</script>

<style lang="scss" >
.patientRoom_tabs {
  background-color: #fff;
  border-radius: 5px;
}
.link_blocks {
  border-bottom: 1px solid #c0c4cc;
}
.link_blocks a {
  padding: 10px;
  display: inline-block;
  margin-bottom: -1px;
  color: #303030;
}

.link_blocks a:hover {
  text-decoration: none;
}
.link_blocks .router-link-exact-active.router-link-active {
  border-bottom: 1px solid #007bff;
  color: #007bff;
}
.minH {
  min-height: 800px;
}
.dNOmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>